import React from "react"
import SEO from "../../components/common/seo"
import { css } from "@emotion/core"

type PolicyType = {
  head: string
  contents: string[]
  indentedContents: string[]
  doubleIndentedContents: string[]
}

function privacyPolicy() {
  //mapping 할 데이터
  const pPTermsFirstToSecond: PolicyType[] = [
    {
      head: ``,
      contents: [
        `주식회사 플랜즈커피(이하 "회사")는 이용자의 개인정보를 중요시하며 개인정보보호법 등 관련법령을 준수하고 있습니다. 
      개인정보취급방침은 회사가 서비스를 제공시 개인정보를 처리하는 과정에서 준수해야 할 지침을 의미하며 회사는 개인정보취급방침을 
      통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 
      알려드립니다.`,
      ],
      indentedContents: [""],
      doubleIndentedContents: [""],
    },
    {
      head: `1. 수집하는 개인정보 항목 및 수집목적`,
      contents: [""],
      indentedContents: [
        `- 휴대폰 번호 : 회원제 서비스 이용에 따른 본인확인, 불량회원의 부정이용 및 비인가 사용 방지, 고지사항 전달 등 의사소통 
        경로의 확보`,
        `- 카드결제정보 : 서비스 이용에 따른 요금 정산 및 결제 `,
      ],
      doubleIndentedContents: [
        `※ 회사는 더 나은 서비스를 제공하기 위해서 "쿠키(cookie)" 를 이용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 
서버가 기기의 브라우저에 보내는 작은 텍스트 파일로서 기기의 컴퓨터 하드디스크에 저장되기도 합니다. 회사는 이를 
통하여 접속 빈도나 방문 시간 등 서비스 이용기록을 분석하여 영업관련 통계 도출 및 서비스 개선의 목적으로 사용합니다.`,
      ],
    },
    {
      head: `2. 수집방법`,
      contents: [""],
      indentedContents: [
        `- 서비스이용시 회원제 서비스 이용을 원하는 경우 휴대폰 번호를 입력받으며 서비스 이용요금 결제 시 카드결제정보를 
        제공받습니다.`,
        `- 회사는 서비스 제공에 필요한 최소한의 개인정보를 수집하고 있으며 서비스 이용에 필수항목 정보를 제공하지 않을 경우 관련 
        서비스 제공이 불가합니다.`,
        `- 회사는 서비스 이용시 광고성 정보 수신에 별도 동의한 회원에 한하여 해당 목적 사용을 위하여 휴대폰 번호를 수집합니다.`,
        `- 회사는 관련 법령상 명시적인 예외의 경우를 제외하고는 어떠한 경우라도 입력하신 개인정보를 사전에 밝힌 목적 이외에 다른 
        목적으로는 사용하지 않으며 외부로 유출하지 않습니다.`,
      ],
      doubleIndentedContents: [""],
    },
  ]

  const pPTermsFifthToSixth: PolicyType[] = [
    {
      head: `5. 개인정보의 제3자 제공`,
      contents: [
        `회원의 개인정보는 개인정보의 수집 및 및 이용목적에서 동의한 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여
        이용하거나 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.`,
      ],
      indentedContents: [
        `- 이용자들이 사전에 동의한 경우
        `,
        `- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`,
      ],
      doubleIndentedContents: [""],
    },
    {
      head: `6. 개인정보의 처리 위탁`,
      contents: [
        `회사는 서비스의 원활한 제공을 위해 개인정보를 위탁 처리하고 있으며, 관련법령에 따라 위탁계약시 개인정보가 안전하게 관리될 수
            있도록 필요한 사항을 규정하고 있습니다. 또한 위탁 처리하는 정보는 서비스 제공과 관련하여 필요한 최소한의 정보에 국한됩니다.
            현재 회사의 개인정보처리 수탁자와 그 업무의 내용은 다음과 같습니다.`,
      ],
      indentedContents: [""],
      doubleIndentedContents: [""],
    },
  ]

  const pPTermsFifthToNinth: PolicyType[] = [
    {
      head: `7. 회원의 권리와 그 행사방법`,
      contents: [
        `회원은 회사의 개인정보 관리책임자에 연락하여 언제든지 수집 정보에 대하여 동의 열람, 정정, 동의 철회 및 삭제, 처리정지 등을 요청할 
        수 있습니다. 다만, 동의 철회, ․삭제시 서비스의 일부 또는 전부 이용이 제한될 수 있습니다.`,
        `개인정보 오류에 대한 정정을 요청시 정정이 완료될 때 까지 해당 정보를 이용 또는 제공하지 않습니다. 오류가 포함된 개인정보를 제 3
        자에게 이미 제공한 경우에는 정정 내용을 제공받은 자에게 통지하여 조치합니다.`,
        `회원의 요청에 따라 삭제된 개인정보는 3. 개인정보의 보유 및 이용기간에 명시된 바에 따라 처리합니다. 다만, 위와 같은 요청시, 서비스
        의 일부 또는 전부 이용이 제한될 수 있습니다.`,
      ],
      indentedContents: [],
      doubleIndentedContents: [],
    },
    {
      head: `8. 개인정보보호를 위한 기술적/관리적 대책`,
      contents: [
        `회사는 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 
          기술적, 관리적 대책을 강구하고 있습니다.`,
        `회원의 휴대폰 번호를 전송하는 경우에는 암호화하여 전송 하고 있으며, 개인정보의 확인 및 변경도 본인에 의해서 혹은 본인의 요청에 
          의해서만 가능합니다.`,
        `회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.`,
        `개인정보관련 취급 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 
          실시하며 별도의 비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.`,
        `회원이 서비스 이용시 사용하는 휴대폰번호는 회원 본인이 사용하는 것으로 간주됩니다. 회사는 회원의 개인적인 부주의로 
          개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않으므로, 이용자 개개인이 
          자신의 개인정보를 적절하게 관리하여 개인정보가 유출되지 않도록 주의해 주시기 바랍니다.`,
      ],
      indentedContents: [""],
      doubleIndentedContents: [""],
    },
    {
      head: `9. 개인정보보호책임자 및 관련민원서비스`,
      contents: [
        `회사는 회원이 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 회원에게 고지한 사항들에 
          반하는 사고가 발생할 시에는 회사가 모든 책임을 집니다. 그러나, 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인
           네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서 
          회사는 책임을 지지 않습니다. 이용자의 개인정보를 처리하는 책임자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 
          답변해 드리고 있습니다.`,
      ],
      indentedContents: [""],
      doubleIndentedContents: [""],
    },
  ]

  return (
    <>
      <SEO title="PrivacyPolicy" />
      <main css={privacy__policy__wrap}>
        <h1>개인정보취급방침</h1>
        <hr />
        {pPTermsFirstToSecond.map(obj => {
          const {
            head,
            contents,
            indentedContents,
            doubleIndentedContents,
          } = obj

          return (
            <div css={privacy__policy}>
              <h2>{head}</h2>
              <section>
                {contents.map(list => {
                  return <p>{list}</p>
                })}
              </section>
              <article>
                {indentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </article>
              <div>
                {doubleIndentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </div>
            </div>
          )
        })}
        <div css={third__privacy__policy}>
          <h2>3. 개인정보의 보유 및 이용기간</h2>
          <section>
            <p>
              회사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및
              이용목적이 달성되면 지체 없 이 파기합니다. 단, 다음의 정보에
              대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
            </p>
          </section>
          <article>
            <div>
              {"<"}회사의 내부방침에 의한 정보보유 사유{">"}
            </div>
            <p>부정이용기록 : 부정이용 방지</p>
            <p>보존기간 : 부정이용일로부터 1년</p>
          </article>
          <article>
            <div>
              {"<"}관련법령에 의한 정보보유 사유{">"}
            </div>
            <p>
              - 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
            <p>
              - 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
            <p>
              - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
            <p>
              - 표시, 광고에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한
              법률, 6개월
            </p>
            <p>- 전자금융 거래에 관한 기록 : 전자금융거래법, 5년</p>
            <p>
              - 서비스 방문기록(접속로그, 접속아이피) : 통신비밀보호법, 3개월
            </p>
          </article>
        </div>

        <div css={fourth__privacy__policy}>
          <h2>4. 개인정보의 파기 절차 및 방법</h2>
          <section>
            <p>
              이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체
              없이 아래와 같은 방법으로 파기합니다. 또한 1년 동안 서비스를
              이용하지 않은 이용자의 개인정보는 관련법령이 정하는 바에 따라 즉시
              파기하거나 다른 이용자의 개인정보와 분리하여 별도로 저장,
              관리합니다.
            </p>
          </section>
          <article>
            <p>- 파기절차</p>
            <p>
              별도의 DB로 옮겨져 내부방침 및 기타 관련 법령에 의한 정보보호
              사유에 따라(개인정보의 보유 및 이용기간 참조) 일정기간 저장된 후
              파기되며, 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가
              아니고서는 보유목적 이외의 다른 목적으로 이용되지 않습니다.
            </p>
          </article>
          <article>
            <p>- 파기방법</p>
            <p>
              종이에 출력된 정보는 분쇄기로 분괘하거나 소각을 통하여 파기합니다.
              // 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
              기술적 방법을 사용하여 삭제합니다.
            </p>
          </article>
        </div>
        {pPTermsFifthToSixth.map(obj => {
          const {
            head,
            contents,
            indentedContents,
            doubleIndentedContents,
          } = obj
          return (
            <div css={privacy__policy}>
              <h2>{head}</h2>
              <section>
                {contents.map(list => {
                  return <p>{list}</p>
                })}
              </section>
              <article>
                {indentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </article>
              <div>
                {doubleIndentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </div>
            </div>
          )
        })}
        <table css={privacy__policy__table}>
          <th>위탁대상자(수탁업체)</th>
          <th>위탁업무 내용</th>
          <th>개인정보 항목</th>
          <th>보유 및 이용기간</th>
          <tr>
            <td>스마트로(주)</td>
            <td>신용카드 결제사명</td>
            <td>스마트로(주)</td>
            <td rowSpan={2}>
              재화 또는 서비스의 제공 <br />
              목적이 달성된 후 파기 <br />
              (단, 관련 법령에 정해진 <br />
              규정에 따라 법정기간 <br />
              동안 보관)
            </td>
          </tr>
          <tr>
            <td>Google.Inc</td>
            <td>
              운영을 위한 <br />
              Global Cloud <br />
              서비스 사용
            </td>

            <td>
              전화번호 <br />
              구매기록 <br />
              쿠폰정보
            </td>
          </tr>
        </table>
        {pPTermsFifthToNinth.map(obj => {
          const {
            head,
            contents,
            indentedContents,
            doubleIndentedContents,
          } = obj
          return (
            <div css={privacy__policy}>
              <h2>{head}</h2>
              <section>
                {contents.map(list => {
                  return <p>{list}</p>
                })}
              </section>
              <article>
                {indentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </article>
              <div>
                {doubleIndentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </div>
            </div>
          )
        })}
        <section css={tenth__privacy__policy}>
          <h2>10. 개인정보취급방침 변경</h2>
          <article>
            <p>개인정보보호책임자 성명 : 김지환</p>
            <p>전화번호 : 02-6956-0232</p>
            <p>이메일 : admin@planz-coffee.com</p>
          </article>
          <div>
            또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는
            아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
          </div>
          <article>
            <p>개인정보침해신고센터</p>
            <p>전화 (국번 없이) 118</p>
            <p>홈페이지 http://privacy.kisa.or.kr</p>
          </article>
          <article>
            <p>대검찰청 사이버범죄수사단</p>
            <p>전화 02-3480-3571</p>
            <p>http:-/www.spo.go.kr</p>
          </article>
          <article>
            <p>경찰청 사이버안전국</p>
            <p>전화 (국번 없이)182</p>
            <p>홈페이지 http://cyberbureau.police.go.kr</p>
          </article>
          <div>
            회사는 위 내용에 대한 추가, 삭제 및 수정이 있을 경우 변경사항의 시행
            전 공지합니다.
          </div>
          <div>본 개인정보취급방침은 8월 31일부터 시행됩니다.</div>
        </section>
      </main>
    </>
  )
}

const privacy__policy__wrap = css`
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (min-width: 850px) {
    width: 790px;
  }
  word-break: keep-all;

  h1 {
    font-family: Spoqa Han Sans;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  hr {
    margin-top: 28px;
    margin-bottom: 37px;
    border: 1px solid #707070;
  }
`
const privacy__policy = css`
  margin-bottom: 67px;
  font-family: Spoqa Han Sans;

  h2 {
    margin-bottom: 37px;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  section {
    p {
      margin-bottom: 37px;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
  article {
    margin-top: 37px;
    margin-left: 50px;

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
  div {
    margin-left: 100px;
    margin-top: 37px;

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
`

const third__privacy__policy = css`
  font-family: Spoqa Han Sans;
  margin-bottom: 67px;
  h2 {
    margin-bottom: 37px;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  section {
    color: #707070;

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
    }
  }
  article {
    color: #707070;
    margin-left: 50px;
    margin-top: 37px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    p {
      margin-left: 50px;
    }
  }
`

const fourth__privacy__policy = css`
  font-family: Spoqa Han Sans;
  margin-bottom: 67px;

  h2 {
    margin-bottom: 37px;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  section {
    color: #707070;
    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
    }
  }
  article {
    color: #707070;
    margin-left: 50px;
    margin-top: 37px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }
`
const privacy__policy__table = css`
  margin-top: 63px;
  margin-bottom: 67px;
  width: 100%;

  th {
    width: 25%;
    height: 63px;
    background-color: #f2f2f2;
    border: 1px solid #e2e2e2;
    font-family: Spoqa Han Sans;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    vertical-align: middle;
  }
  td {
    width: 272px;
    height: 114px;
    border: 1px solid #e2e2e2;
    font-family: Spoqa Han Sans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    vertical-align: middle;
  }
`

const tenth__privacy__policy = css`
  font-family: Spoqa Han Sans;
  h2 {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  article {
    margin-left: 50px;
    margin-top: 37px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  div {
    font-size: 15px;
    margin-top: 37px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
`
export default privacyPolicy
